.App {
  text-align: center;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.reactGridItem {
  transition: all 200ms ease;
  transition-property: left, top;
  background-color: rgb(245, 246, 247);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
}
.deleteButton :hover{
  cursor: pointer;
}
.grid-item__title:hover {
  opacity: 0.2;
}
.dragHandle {
  width: 90%;
  height: 100%;
}
.dragHandle:hover {
  cursor: move;
}
.grid-item-card {
  height: 100%;
}