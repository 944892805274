@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.ag-theme-material {
  --ag-grid-size: 6px;
}

.custom-cell-border {
  border-left: 1px solid #cfcece !important;
}

.first-child-border {
  border-left: 1px solid #cfcece !important;
}

.ag-pinned-left-header .ag-header-cell {
  padding: 0;
  padding-left: 12px;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #2196f3 !important;
}

.sash {
  padding-top: 10px;
  padding-bottom: 10px;
}

.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

.ag-root-wrapper {
  min-width: 1200px !important;
}

@media screen and (max-width: 600px) {
  .MuiChip-label {
    white-space: normal;
  }
}
.MuiTabs-root > .MuiTabs-scroller {
  overflow-x: auto !important;
}

.MuiButton-root.MuiLoadingButton-root {
  text-transform: none;
  border-radius: 24px;
  height: 34px;
  white-space: nowrap;
}

.MuiButton-containedPrimary.MuiLoadingButton-root {
  background-color: #2196f3;
  color: white;
}